import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Brain,
  Code,
  Monitor,
  Wrench,
  ArrowRight,
  Cpu,
  Globe,
  Smartphone,
  Bot,
  Database,
  Rocket,
  Sparkles,
  MessageCircle
} from 'lucide-react';
import { debounce } from 'lodash';

const skills = [
  { name: 'AI', icon: <Brain className="w-5 h-5" /> },
  { name: 'IT', icon: <Monitor className="w-5 h-5" /> },
  { name: 'Web Development', icon: <Code className="w-5 h-5" /> },
  { name: 'Python', icon: <Code className="w-5 h-5" /> },
  { name: 'React', icon: <Code className="w-5 h-5" /> },
  { name: 'Java', icon: <Code className="w-5 h-5" /> },
  { name: 'Problem Solving', icon: <Wrench className="w-5 h-5" /> },
  { name: 'Data Analysis', icon: <Brain className="w-5 h-5" /> },
  { name: 'Cloud Computing', icon: <Monitor className="w-5 h-5" /> },
  { name: 'JavaScript', icon: <Code className="w-5 h-5" /> },
  { name: 'Mobile Development', icon: <Smartphone className="w-5 h-5" /> },
];

const projectSummaries = [
  {
    title: "Local LLM Development",
    description: "Building and fine-tuning Large Language Models locally for specialized tasks. Creating custom AI solutions that run efficiently on local hardware.",
    icon: <Cpu className="w-8 h-8" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    hoverGradient: "from-blue-600/30 to-cyan-600/30"
  },
  {
    title: "AI-Powered Web Apps",
    description: "Developing intelligent web applications that leverage AI for enhanced user experiences, from smart content generation to predictive interfaces.",
    icon: <Globe className="w-8 h-8" />,
    gradient: "from-purple-500/20 to-pink-500/20",
    hoverGradient: "from-purple-600/30 to-pink-600/30"
  },
  {
    title: "Intelligent Automation",
    description: "Creating smart automation systems and bots for lead generation, data scraping, and process optimization. Making businesses more efficient through AI.",
    icon: <Bot className="w-8 h-8" />,
    gradient: "from-green-500/20 to-emerald-500/20",
    hoverGradient: "from-green-600/30 to-emerald-600/30"
  },
  {
    title: "AI Mobile Applications",
    description: "Building mobile apps that integrate AI capabilities for real-time processing, computer vision, and natural language understanding.",
    icon: <Smartphone className="w-8 h-8" />,
    gradient: "from-yellow-500/20 to-orange-500/20",
    hoverGradient: "from-yellow-600/30 to-orange-600/30"
  },
  {
    title: "Data & AI Integration",
    description: "Implementing AI-driven data solutions that transform raw information into actionable insights through advanced analytics and machine learning.",
    icon: <Database className="w-8 h-8" />,
    gradient: "from-red-500/20 to-rose-500/20",
    hoverGradient: "from-red-600/30 to-rose-600/30"
  },
  {
    title: "Experimental AI Projects",
    description: "Exploring cutting-edge AI technologies and creating innovative solutions that push the boundaries of what's possible with artificial intelligence.",
    icon: <Rocket className="w-8 h-8" />,
    gradient: "from-indigo-500/20 to-violet-500/20",
    hoverGradient: "from-indigo-600/30 to-violet-600/30"
  }
];

const AIChatPrompt = ({ onClose }) => {
  return (
    <div className="fixed bottom-24 right-4 bg-gradient-to-r from-purple-600/90 to-pink-600/90
      rounded-lg shadow-xl p-4 max-w-xs animate-float z-50 backdrop-blur-sm border border-white/10">
      <button
        onClick={onClose}
        className="absolute -top-2 -right-2 bg-purple-700 rounded-full p-1 hover:bg-purple-800
          transition-colors duration-300"
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="flex items-start space-x-3">
        <MessageCircle className="w-6 h-6 text-white/90 mt-1" />
        <div>
          <p className="text-white/90 text-sm font-medium mb-2">
            👋 Hi there! I'm Christophe's AI assistant.
          </p>
          <p className="text-white/80 text-sm">
            Want to learn more about my work with AI and tech? Feel free to ask me anything!
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Home() {
  const [activeCard, setActiveCard] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isScrolled, setIsScrolled] = useState(false);
  const [showAIPrompt, setShowAIPrompt] = useState(false);

  // Optimize scroll handler with useCallback
  const handleScroll = useCallback(() => {
    const scrolled = window.scrollY > 50;
    setIsScrolled(scrolled);
  }, []);

  // Debounce mouse move handler
  const handleMouseMove = useMemo(
    () =>
      debounce((e) => {
        setMousePosition({
          x: e.clientX,
          y: e.clientY,
        });
      }, 10),
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Show AI prompt after a delay
  useEffect(() => {
    const promptTimer = setTimeout(() => {
      setShowAIPrompt(true);
    }, 15);

    return () => clearTimeout(promptTimer);
  }, []);

  // Memoize gradient style to prevent unnecessary recalculations
  const gradientStyle = useMemo(
    () => ({
      background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px,
        rgba(139, 92, 246, 0.1) 0%,
        rgba(0, 0, 0, 0) 50%)`,
    }),
    [mousePosition.x, mousePosition.y]
  );

  return (
    <div
      className="min-h-screen relative overflow-hidden bg-black"
      onMouseMove={handleMouseMove}
    >
      {/* Interactive Background Gradient */}
      <div
        className="fixed inset-0 z-0 transition-opacity duration-1000"
        style={gradientStyle}
      />

      {/* Background Video with Enhanced Overlay */}
      <div className="fixed inset-0 z-0">
        <div
          className={`absolute inset-0 bg-gradient-to-b from-black/95 via-[#0f0c29]/90 to-black/95 z-10
            transition-opacity duration-700 ${isScrolled ? 'opacity-95' : 'opacity-85'}`}
        />
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-1/2 left-1/2 min-w-full min-h-full object-cover -translate-x-1/2 -translate-y-1/2 opacity-30"
          style={{ filter: 'brightness(0.3) saturate(1.2)' }}
        >
          <source src="/assets/vid1.mp4" type="video/mp4" />
        </video>
      </div>

      {/* Floating Elements */}
      <div className="fixed inset-0 pointer-events-none">
        <div className="absolute top-20 left-10 w-32 h-32 bg-purple-500/10 rounded-full blur-xl animate-float" />
        <div className="absolute bottom-20 right-10 w-40 h-40 bg-blue-500/10 rounded-full blur-xl animate-float-delayed" />
      </div>

      {/* Main Content */}
      <div className="relative z-10 container mx-auto px-4 py-20">
        <div className="max-w-6xl mx-auto space-y-24">
          {/* Hero Section */}
          <div className="space-y-8 text-center md:text-left relative">
            <Sparkles className="absolute -top-10 -left-10 w-20 h-20 text-purple-500/20 animate-pulse" />

            <div className="inline-block px-6 py-2 rounded-full backdrop-blur-sm animate-fadeIn
              bg-gradient-to-r from-purple-600/20 to-blue-600/20 border border-white/10
              transform hover:scale-105 transition-all duration-300">
              <h3 className="text-white font-medium">Exploring the Future of AI</h3>
            </div>

            <h1 className="text-6xl md:text-7xl font-bold mb-6 animate-fadeIn animation-delay-150 leading-tight">
              <span className="text-white">Hi! I'm </span>
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400">
                Christophe Marcellus
              </span>
            </h1>

            <h2 className="text-3xl md:text-4xl text-white/90 mb-8 animate-fadeIn animation-delay-300 font-light">
              Full Stack Developer & AI Enthusiast
            </h2>

            <p className="text-xl text-gray-300 max-w-3xl mx-auto md:mx-0 animate-fadeIn animation-delay-450 leading-relaxed">
              I craft intelligent solutions that push the boundaries of technology. From fine-tuning
              AI models to building smart applications, I turn complex challenges into innovative digital experiences.
            </p>
          </div>

          {/* Skills Section - Now using CSS Grid for better performance */}
          <div className="animate-fadeIn animation-delay-600">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
              {skills.map((skill, index) => (
                <div
                  key={index}
                  className="group flex items-center gap-2 px-5 py-2.5 rounded-full
                    bg-gradient-to-r from-white/5 to-white/10 hover:from-purple-600/20 hover:to-blue-600/20
                    text-white transition-all duration-300 backdrop-blur-sm transform
                    hover:translate-y-[-2px] hover:scale-110 border border-white/5
                    cursor-pointer"
                >
                  <span className="transform group-hover:rotate-12 transition-transform duration-300">
                    {skill.icon}
                  </span>
                  <span className="font-medium">{skill.name}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Project Cards - Using CSS Grid for better performance */}
          <div className="mt-20 animate-fadeIn animation-delay-750">
            <h2 className="text-4xl font-bold text-center mb-16 text-transparent bg-clip-text
              bg-gradient-to-r from-purple-400 to-pink-400">
              Crafting Tomorrow's Solutions
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {projectSummaries.map((project, index) => (
                <div
                  key={index}
                  className={`group p-8 rounded-2xl backdrop-blur-sm border border-white/10
                    bg-gradient-to-br ${project.gradient} hover:bg-gradient-to-br
                    ${project.hoverGradient} transform hover:scale-[1.02]
                    transition-all duration-500 cursor-pointer
                    ${activeCard === index ? 'ring-2 ring-purple-500/50' : ''}`}
                  onMouseEnter={() => setActiveCard(index)}
                  onMouseLeave={() => setActiveCard(null)}
                >
                  <div className="text-white/90 mb-6 transform transition-all duration-300
                    group-hover:scale-110 group-hover:rotate-12">
                    {project.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-white mb-4 group-hover:text-transparent
                    group-hover:bg-clip-text group-hover:bg-gradient-to-r from-purple-400 to-pink-400">
                    {project.title}
                  </h3>
                  <p className="text-gray-300 leading-relaxed transform transition-all duration-300
                    group-hover:text-white">
                    {project.description}
                  </p>
                </div>
              ))}
            </div>

            {/* Call to Action */}
            <div className="mt-16 flex justify-center">
              <Link
                to="/projects"
                className="group relative px-8 py-4 bg-gradient-to-r from-purple-600/80 to-pink-600/80
                  rounded-full text-white font-semibold transition-all duration-300
                  transform hover:scale-105 hover:from-purple-500 hover:to-pink-500
                  shadow-lg shadow-purple-500/25 overflow-hidden"
              >
                <span className="relative z-10 flex items-center gap-2">
                  See How I'm Using These in Projects
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600
                  opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </Link>
            </div>
          </div>
        </div>
      </div>

        {/* AI Chat Prompt */}
        {showAIPrompt && <AIChatPrompt onClose={() => setShowAIPrompt(false)} />}
    </div>
  );
}
